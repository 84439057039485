import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { keyframes } from "@emotion/react";
import brainAnimation from "../../assets/BrainAnimation.gif";

// Animation for sliding in from the left
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const sections = [
  {
    title: "Interactive Courses for All Levels",
    content:
      "Explore our wide range of neuroscience courses, designed to cater to both beginners and advanced learners. With engaging content, interactive lessons, and expert guidance, our courses will help you deepen your understanding of the brain and its functions.",
  },
  {
    title: "Insights & Discoveries in Neuroscience",
    content:
      "Dive into our blog where we share thought-provoking articles, research summaries, and expert insights into the world of neuroscience. Explore a range of topics, from brain health and neurotechnology to educational resources.",
  },
  {
    title: "Stay Informed with Our Latest Updates",
    content:
      "Subscribe to our newsletter to receive the latest news, updates, and exclusive content right in your inbox. Be the first to know about our upcoming events, new research findings, and helpful resources to enhance your learning experience.",
  },
];

const OverlaySection = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  // Detect when the section is in view and apply the animation
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("overlay-section");
      const rect = element.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        setIsVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNext = () => {
    setCurrentSection((prev) => (prev + 1) % sections.length);
  };

  const handlePrev = () => {
    setCurrentSection((prev) => (prev - 1 + sections.length) % sections.length);
  };

  return (
    <Box
      id="overlay-section"
      sx={{
        position: "relative",
        backgroundColor: "#87CEFA",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "2rem",
        animation: isVisible ? `${slideIn} 1s ease` : "none",
        transition: "opacity 1s",
        opacity: isVisible ? 1 : 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        height: { xs: "200px", sm: "300px" }, // Adjust height for mobile
      }}
    >
      {/* Left Arrow */}
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          left: "10px", // Adjust position for mobile
          top: "50%",
          transform: "translateY(-50%)",
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
          fontSize: { xs: "small", sm: "medium" }, // Smaller size for mobile
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>

      {/* Centered Content */}
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: { xs: "1rem", sm: "2rem" }, // Adjust padding for mobile
          // borderRadius: "10px",
          width: { xs: "90%", sm: "800px" }, // Adjust width for mobile
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: "0.5rem",
            fontWeight: "bold",
            fontSize: { xs: "1.25rem", sm: "1.75rem" },
          }} // Smaller font size for mobile
        >
          {sections[currentSection].title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontStyle: "italic", fontSize: { xs: "0.875rem", sm: "1rem" } }}
        >
          {sections[currentSection].content}
        </Typography>
      </Box>

      {/* Right Arrow */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: "10px", // Adjust position for mobile
          top: "50%",
          transform: "translateY(-50%)",
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
          fontSize: { xs: "small", sm: "medium" }, // Smaller size for mobile
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default OverlaySection;
