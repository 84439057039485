import React, { useState } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faEye, faHeart } from "@fortawesome/free-solid-svg-icons";
const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%", // Use full width on mobile
  maxWidth: "400px", // Adjust card max width for larger screens
  margin: "16px",
  padding: "24px",
  // borderRadius: "16px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  height: "auto", // Set height to auto to adapt to content size
  [theme.breakpoints.up("md")]: {
    height: "250px", // Maintain fixed height for larger screens
  },
  "&:hover": {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
    transform: "translateY(-5px)",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Urbanist",
  fontWeight: "bold",
  fontSize: "1.6rem",
  color: "#333",
  textAlign: "center",
  marginBottom: "8px",
  zIndex: "1",
  verticalAlign: "center",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem", // Larger font size for bigger screens
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "2.5rem",
  color: "#333",
  marginBottom: "16px",
  zIndex: "1",
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem", // Larger icon size for bigger screens
  },
}));

const Content = styled(Typography)(({ theme }) => ({
  fontFamily: "Urbanist",
  fontSize: "0.9rem",
  color: "#555",
  lineHeight: "1.6",
  textAlign: "center",
  zIndex: "1",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem", // Adjust font size for larger screens
  },
}));

export default function MissionVisionValues() {
  const cards = [
    {
      title: "MISSION",
      icon: faBullseye,
      content:
        "Our mission is to deliver the latest neuroscience content to educate, inform, and inspire our audience.",
    },
    {
      title: "VISION",
      icon: faEye,
      content:
        "Our vision is to bridge the gap between complex scientific discoveries and the general public, making cutting-edge research accessible and engaging.",
    },
    {
      title: "VALUES",
      icon: faHeart,
      content:
        "We value fostering a deeper understanding of the brain and its functions, promoting curiosity and advancements in the field of neuroscience.",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens, row on larger screens
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(121, 80, 234)",
        padding: "32px",
        minHeight: "60vh",
      }}
    >
      {cards.map((card, index) => (
        <CardContainer
          key={index}
          sx={{
            background: "#FFF",
            color: "#333",
          }}
        >
          <IconWrapper>
            <FontAwesomeIcon icon={card.icon} />
          </IconWrapper>
          <Title>{card.title}</Title>
          <Content>{card.content}</Content>
        </CardContainer>
      ))}
    </Box>
  );
}
