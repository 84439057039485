import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "./components/Header";
import Chatbot from "./containers/ChatbotSection/Chatbot";
import Home from "./containers/LandingPageSection/Home";
import About from "./containers/AboutUsSection/LeadershipPanel";
import Contact from "./containers/HelpSection/Contact";
import Newsletter from "./containers/Newsletter/Newsletter";
import Blog from "./containers/BlogSection/Blog";
import Articles from "./containers/ArticleSection/Articles";
import Courses from "./containers/CoursesSection/Courses";
import Footer from "./components/Footer";
import Animation from "./containers/Animation";
import AddArticles from "./containers/ArticleSection/AddArticles";
import NotFoundPage from "./components/NotFound";
import { validPaths } from "./constants";
function App() {
  const currentRoute = window.location.pathname;
  const userid = localStorage.getItem("userid")

  return (
    <Box >
      { validPaths.includes(currentRoute)  && <Header route={currentRoute} />}
<Chatbot/>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/leadership-panel" element={<About/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/newsletter" element={<Newsletter/>}></Route>
          <Route path="/blog" element={<Blog/>}></Route>
          <Route path="/our-story" element={<Animation/>}></Route>
          <Route path="/courses" element={<Courses/>}></Route>
          <Route path="/articles" element={<Articles/>}></Route>
          {Number(userid)===17&&<Route path="/addArticles" element={<AddArticles/>}></Route>}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      {window.location.pathname!=='/articles' && window.location.pathname!=='/courses' && validPaths.includes(currentRoute)  && <Footer/>}
    </Box>
  );
}

export default App;
