import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  CardMedia,
} from "@mui/material";
import firstCourseImage from "../../assets/article.jpg";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import firstCourse from "../../assets/B4B Beginner Course Part II.mp4";
const Courses = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleViewCourse = () => setShowVideo(true);

  const toggleLike = () => setIsLiked((prev) => !prev);

  const handleShare = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        padding: { xs: 2, sm: 4 },
      }}
    >
      {!showVideo ? (
        <Card
          sx={{
            maxWidth: { xs: "100%", sm: 600 },
            width: "100%",
            mb: 2,
          }}
        >
          <CardMedia
            component="img"
            height="200"
            image={firstCourseImage}
            alt="Neuroscience Course"
          />
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              Behavioral Neuroscience
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 1.5, fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              The field of Behavioral Neuroscience is the study of the
              biological basis of behavior in humans and animals
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures spacing between buttons
              alignItems: "center", // Vertical alignment
            }}
          >
            <IconButton aria-label="share" onClick={handleShare}>
              <ShareIcon />
            </IconButton>
            <Button
              variant="contained"
              startIcon={<PlayCircleIcon />}
              onClick={handleViewCourse}
              sx={{
                marginLeft: "auto",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black", // Maintain black color on hover
                },
              }}
            >
              View Course
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowVideo(false)}
            sx={{
              mb: 2,
              fontSize: { xs: "0.8rem", sm: "1rem" },
              width: { xs: "90%", sm: "auto" },
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "black", // Maintain black color on hover
              },
              alignSelf: "flex-start", // Aligns button to the left
            }}
          >
            Back to Course
          </Button>
          <video
            src={firstCourse}
            controls
            style={{
              width: "100%",
              maxWidth: "100%",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          />
        </Box>
      )}

      {/* Share Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: 300 },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: { xs: 2, sm: 4 },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}
            >
              Share Course
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            value={window.location.href}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <Tooltip title="Copy to clipboard">
            <Button
              variant="contained"
              onClick={() =>
                navigator.clipboard.writeText(window.location.href)
              }
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" },
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black", // Maintain black color on hover
                },
              }}
            >
              Copy Link
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    </Box>
  );
};

export default Courses;
